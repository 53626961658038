<template id="result">
  <div align="center">
    <ModalForm :visible="show" @close="show = false" :message="modalMsg" />
    <!-- Modal -->
    <div
      class="modal"
      id="pdfModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ pdfFile.adj_nmbre }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!--
              <div ref="pdfcontainer" align="center"></div>
            -->
            <iframe
              v-bind:src="pdfFile.url"
              style="width: 95%; height: 500px"
              frameborder="0"
            ></iframe>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              v-on:click="tabPdf"
              class="btn btn-success"
              data-dismiss="modal"
            >
              Pantalla completa
            </button>
            <button
              type="button"
              v-on:click="clearPdf"
              class="btn btn-success"
              data-dismiss="modal"
            >
              Regresar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="card col-md-8 rounded shadow-lg" style="text-align: left">
      <div class="card-body">
        <div class="card-title">
          <h1>Resultado de la búsqueda</h1>
        </div>
      </div>
    </div>
    <div
      class="card col-md-8 rounded shadow-lg"
      style="text-align: left;"
    >
      <ul class="timeline">
        <li>
          <div class="card-body">
            <div class="form-group row">
              <label class="col-md-12 col-form-label text-primary">{{
                pqrs.dcm_fcharad | formatDate
              }}</label>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-form-label">Radicado inicial:</label>
              <input
                type="text"
                class="form-control col-md-8"
                v-model="pqrs.rad"
                disabled="true"
                style="color: red"
              />
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-form-label">Nombre completo:</label>
              <input
                type="text"
                class="form-control col-md-8"
                v-model="pqrs.ent_nmbre"
                disabled="true"
              />
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-form-label">Asunto:</label>
              <textarea
                rows="3"
                class="form-control col-md-8"
                v-model="pqrs.dcm_asunto"
                disabled="true"
              ></textarea>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-form-label" style="color: green"
                >Estado:</label
              >
              <input
                type="text"
                class="form-control col-md-8"
                v-model="pqrs.est_nmbre"
                disabled="true"
                style="color: green"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      class="card col-md-8 rounded shadow-lg"
      style="text-align: left"
      v-for="doc in relations"
      :key="doc._id"
    >
      <ul class="timeline">
        <li>
          <div class="card-body">
            <div class="form-group row">
              <label class="col-md-12 col-form-label text-primary">{{
                doc.dcm_fcharad | formatDate
              }}</label>
            </div>

            <div class="form-group row">
              <label
                class="col-md-4 col-form-label"
                :style="{
                  color: doc._id == pqrs.dcm_id_rs ? 'green' : '',
                }"
                >{{ get_name_radicado(doc) }}:</label
              >
              <input
                type="text"
                class="form-control col-md-8"
                :style="{
                  color: doc._id == pqrs.dcm_id_rs ? 'green' : '',
                }"
                :value="`${doc.tpocor.tcr_cdgo}-${doc.dcm_ano}-${doc.dcm_cdgo}`"
                disabled="true"
              />
            </div>

            <div class="form-group row">
              <label class="col-md-4 col-form-label">{{
                doc._id == pqrs._id ? "Nombre completo:" : "Entidad"
              }}</label>
              <input
                type="text"
                class="form-control col-md-8"
                v-model="doc.dcm_entidad"
                disabled="true"
              />
            </div>

            <div
              class="form-group row"
              v-if="doc.dcm_id_rs && doc.ent_id == pqrs.ent_id"
            >
              <label class="col-md-4 col-form-label">Asunto:</label>
              <textarea
                rows="3"
                class="form-control col-md-8"
                :value="doc.dcm_asunto"
                disabled="true"
              ></textarea>
            </div>

            <div
              class="form-group row"
              v-if="doc.dcm_id_rs && doc.ent_id == pqrs.ent_id"
            >
              <label class="col-md-4 col-form-label" style="color: green"
                >Estado:</label
              >
              <input
                type="text"
                class="form-control col-md-8"
                :value="'Solicitud respondida'"
                disabled="true"
                style="color: green"
              />
            </div>

            <div v-if="doc._id == pqrs.dcm_id_rs && doc.ent_id == pqrs.ent_id">
              <br />
              <div class="form-group" id="_adjuntos">
                <label><b style="color: darkblue">ARCHIVOS ADJUNTOS</b></label>
                <div class="large-12 medium-12 small-12 cell">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th></th>
                        <th>Nombre de Archivo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="j in files" :key="j._id">
                        <td v-if="j.dcm_id == doc._id">{{ j.index }}</td>
                        <td
                          style="text-align: center"
                          v-if="j.dcm_id == doc._id"
                        >
                          <div v-if="isPdf(j.adj_nmbre)">
                            <a
                              href="#"
                              v-on:click.prevent="
                                viewPdf(j.adj_nmbre, j.temp, j._id, j.dcm_id)
                              "
                            >
                              <i
                                class="far fa-file-pdf"
                                style="font-size: 18px; color: red"
                                title="Ver documento"
                              ></i>
                            </a>
                            <viewpdf v-bind:pdfFile="pdfFile"></viewpdf>
                            <img v-if="j.download" src="/images/loading.gif" />
                          </div>
                          <div v-else>
                            <a
                              href="#"
                              v-on:click.prevent="
                                downloadFile(
                                  j.adj_nmbre,
                                  j.temp,
                                  true,
                                  j._id,
                                  j.dcm_id
                                )
                              "
                            >
                              <i
                                class="fas fa-download"
                                style="font-size: 18px; color: blue"
                                title="Descargar documento"
                              ></i>
                            </a>
                            <div v-if="j.download" class="progress">
                              <div
                                class="progress-bar bg-success"
                                role="progressbar"
                                :style.prop="
                                  percentageValue(j.downloadPercentage)
                                "
                                :aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                Descargando
                              </div>
                            </div>
                          </div>
                        </td>
                        <td v-if="j.dcm_id == doc._id">{{ j.adj_nmbre }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      class="card col-md-8 rounded shadow-lg"
      style="text-align: left"
      v-if="pqrs.est_nmbre == 'Solicitud respondida.'"
    >
      <v-btn
        depressed
        color="primary"
        @click="$router.replace({ name: 'NewPqrs', params: { pqrs: pqrs } })"
      >
        Crear solicitud relacionada
      </v-btn>
    </div>
  </div>
</template>
<script>
import ModalForm from '../modal/ModalForm.vue';
import { formatDate, formatDateUtc } from "./../../plugins/filters";
import ViewPdf from "../doc/ViewPdf.vue";

export default {
  components: {
    ModalForm,
    viewpdf: ViewPdf,
  },
  data() {
    return {
      pqrs: {},
      resp: false,
      rspsta: "",
      files: [],
      pdfFile: {},
      timestamp: 0,
      relations: [],
      fecha_resp: null,
      modalMsg: '',
      show: false,
    };
  },
  filters: {
    formatDate,
    formatDateUtc,
  },
  created() {
    this.fetchTimeStamp();
    this.pqrs = this.$route.params.pqrs;
    this.cmp = this.$store.state.company;
    this.usu = this.$store.state.item.usu_id;

    if (this.pqrs.dcm_expediente){
      this.findRelations(
        this.cmp,
        this.pqrs.dcm_expediente,
        this.pqrs._id,
        this.pqrs.dcm_id_rs,
        () => {}
      );
    }
  },
  methods: {
    get_name_radicado(item) {
      if (item._id == this.pqrs._id) return "Radicado inicial";
      if (item._id == this.pqrs.dcm_id_rs) return "Respuesta";

      return "Radicado";
    },
    findRelations(cmp_id, dcm_expediente, _id, dcm_id_rs, callback) {
      let uri = "/docs/relations-pqrs";
      this.axios
        .post(uri, {
          cmp_id: cmp_id,
          dcm_expediente: dcm_expediente,
          _id: _id,
          dcm_id_rs: dcm_id_rs,
        })
        .then((response) => {
          this.files = [];
          this.relations = response.data;

          for (const doc of this.relations) {
            let count = 0;

            for (const item of doc.adjuntos) {
              this.files.push({
                ...item,
                temp: false,
                uploaded: "0",
                dcm_id: doc._id,
                index: ++count,
              });
            }
          }
          return callback();
        })
        .catch((err) => {
          this.relations = [];
          return callback();
        });
    },
    fetchTimeStamp() {
      let uri = "/api/timestamp";
      this.axios
        .post(uri)
        .then((response) => {
          this.timestamp = response.data.timestamp;
        })
        .catch((err) => {
          alert(
            "No se pudo crear el identificador temporal. Consulte con el administrador."
          );
        });
    },
    downloadFile(adj_nmbre, temp, download, _id, dcm_id) {
      let id = this.files.findIndex((e) => e._id == _id);

      this.files[id].download = true;
      this.files[id].downloadPercentage = 0;
      this.$set(this.files, id, this.files[id]);

      const downloadProgress = (id) => (progressEvent) => {
        this.files[id].downloadPercentage = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
        this.$set(this.files, id, this.files[id]);
      };

      this.axios({
        url: "/api/download",
        method: "POST",
        data: {
          cmp_id: this.cmp,
          _id: dcm_id,
          usu_cdgo: this.usu,
          adj_nmbre: adj_nmbre,
          temp: temp,
          download: download,
          ts: this.timestamp,
        },
        responseType: "blob",
        onDownloadProgress: downloadProgress(id).bind(this),
      })
        //.then(wait(5000))
        .then((response) => {
          this.files[id].download = false;
          this.$set(this.files, id, this.files[id]);
          const url = window.URL.createObjectURL(
            new File([response.data], adj_nmbre, {
              type: "application/octet-stream",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", adj_nmbre);
          //link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.files[id].download = false;
          this.$set(this.files, id, this.files[id]);
        });
    },
    percentageValue(p) {
      return "width:" + p + "%";
    },
    isPdf(adj_nmbre) {
      if (adj_nmbre) {
        var ext = adj_nmbre.substring(adj_nmbre.lastIndexOf("."));
        if (ext.toLowerCase() == ".pdf") {
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
    async viewPdf(adj_nmbre, temp, id, dcm_id) {
      try {
        this.modalMsg = 'Por favor espere mientras se muestra el archivo..';
        this.show = true;

        this.pdfFile = {};
        let path = '/';
        var data = {       
            _id: dcm_id,
            cmp_id: this.cmp,
            path: path,
            name: encodeURIComponent(adj_nmbre)
        }
        
        let base_url = process.env.NODE_ENV === "production" ? "" : "http://localhost:4000";

        const response = await this.axios({
          url: `${base_url}/filesystemprovider/pass-out`,
          method: 'POST',
          data
        });

        const token = response?.data?.tk;
        var url = `${base_url}/filesystemprovider/view-file?tk=${encodeURIComponent(token)}`;

        this.pdfFile = {
          url: url,
          adj_nmbre: adj_nmbre
        };

        $('#pdfModal').modal('show');
        this.show = false;
      } catch (error) {
        this.show = false;
      }
    },
    clearPdf() {
      this.pdfFile.url = "";
    },
    tabPdf() {
      //const url = window.URL.createObjectURL(new File([response.data], adj_nmbre, {type: 'application/octet-stream'}));
      const link = document.createElement("a");
      link.href = this.pdfFile.url;
      //link.setAttribute('download', adj_nmbre);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    },
    //END METHODS
  },
};
</script>
<style>
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 30px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
</style>